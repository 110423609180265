import React, { useEffect } from 'react';

export const useRunOnce = (fn: () => void) => {
    const hasRun = React.useRef(false);

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            fn();
        }

        // intentionally running this one time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return;
};
